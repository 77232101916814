$(document).ready(initPage);

function initPage() {
  function checkScroll() {
    if($(window).scrollTop() > 0){
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled')
    }
  }

  checkScroll();

  $(window).scroll(function(){
    checkScroll();
  });
  
  
  $('.toggler').click(function(){
    $('body').toggleClass('is-mobile-menu');
  });

  const basicSwiper = new Swiper('.swiper--home-hero', {
    slidesPerView: '1',
    effect: 'fade',
    speed: 600,
    loop: true,
    autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true
    },
    navigation: {
      nextEl: '.swiper--home-hero .swiper-next',
      prevEl: '.swiper--home-hero .swiper-prev',
    },
    pagination: {
      el: '.swiper--home-hero .swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      320: {
        spaceBetween: 20
      },
      576: {
        spaceBetween: 44
      },

    }
  });
}
